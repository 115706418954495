<template>
  <tbody>
    <tr 
      v-for="siniestro in siniestros.data" 
      :key="siniestro"
    >
      <td 
        v-if="siniestro.expediente == null"
      >
        Sin nro. expediente
      </td>
      <td 
        v-else
      >
        <b>
          {{siniestro.expediente}}
        </b>
      </td>
      <td 
        v-if="siniestro.colaborador"
        >
        {{
          siniestro?.colaborador?.name == null ? 
          siniestro?.colaborador?.user_name : 
          siniestro?.colaborador?.name 
        }} 
      </td>
      <td 
        v-else
      >
        <b>
          Colaborador eliminado
        </b>
      </td>
      
      <td>
        <template 
          v-for= "(cliente,key) in siniestro.clientes" 
          :key="cliente"
        >
          {{cliente.name}}
          <template 
            v-if="siniestro.clientes.length != (key+1)"
          >
            <br> 
          </template>
        </template>
      </td>

      <td 
        style="font-size: 12px"
      >
        {{new Date(siniestro?.created_at).toLocaleDateString()}} 
        <br> 
        {{new Date(siniestro?.created_at).toLocaleTimeString() }}
      </td>
      
      <td>
        <ion-row>
          <ion-col>
            <ion-badge 
              v-if="siniestro.notification.find(not => not.user_id == getUser.id && not.new_notification) " 
              color="danger" 
              style="width: 18px;padding-top: 7px; height: 21px;margin-top: 13px;" 
              title="Nuevas notas no leidos"
            >
            *
            </ion-badge>
          </ion-col>
          <ion-col>
            <ion-button 
              title="Ver documentos" 
              style="font-size: 11px" 
              color="primary"  
              @click="showDocumentos(siniestro)">
              <ion-icon  
                :icon="folder" 
                :ios="folderOutline" 
                :md="folder">
              </ion-icon>
            </ion-button>
          </ion-col>
          
          <ion-col>
            <ion-button 
              title="Ver notas" 
              style="font-size: 11px" 
              color="primary"  
              @click="showNota(siniestro)"
            >
              <ion-icon  
                :icon="chatbubbles" 
                :ios="chatbubblesOutline" 
                :md="chatbubbles">
              </ion-icon>
            </ion-button>
          </ion-col>
          <ion-col 
            v-if="getUser?.role?.name == 'Despacho'"
          >
            <ion-button 
              title="Editar siniestro" 
              style="font-size: 11px" 
              color="primary"  
              @click="openModalSiniestroEdit(siniestro)"
            >
              <ion-icon  
                :icon="create" 
                :ios="createOutline" 
                :md="create">
              </ion-icon>
            </ion-button>
          </ion-col>
          <ion-col 
            v-if="getUser?.role?.name == 'Despacho'"
          >
            <ion-button 
              v-if="siniestro.finalizado"
              title="Desarchivar Siniestro" 
              style="font-size: 11px" 
              color="primary"  
              @click="desarchivar(siniestro)" 
            >
              <ion-icon 
                :icon="fileTrayFull" 
                :ios="fileTrayFullOutline" 
                :md="fileTrayFull">
              </ion-icon>
              
            </ion-button>
            <ion-button 
              v-else
              title="Archivar/Finalizar Siniestro" 
              style="font-size: 11px" 
              color="primary"  
              @click="archivar(siniestro)" 
            >
              <ion-icon 
                :icon="fileTrayFull" 
                :ios="fileTrayFullOutline" 
                :md="fileTrayFull">
              </ion-icon>
              
            </ion-button>
          </ion-col>
          <ion-col 
            v-if="getUser?.role?.name == 'Despacho'"
          >
            <ion-button 
              title="Eliminar siniestro" 
              style="font-size: 11px" color="primary"  
              @click="eliminarSiniestroConfirmacion(siniestro)" 
            >
              <ion-icon  
                :icon="trash" 
                :ios="trashOutline" 
                :md="trash">
              </ion-icon>
              
            </ion-button>
          </ion-col>
        </ion-row>
      </td>
    </tr>
  </tbody>
</template>

<script>
  
  import { 
    defineComponent 
  } from 'vue';
  
  import {
    alertController,
    modalController,
    createAnimation
  } from '@ionic/vue';

  import { 
    arrowUpOutline,
    arrowDownOutline,
    fileTrayFullOutline,
    fileTrayFull,
    downloadOutline,
    download,
    bookmarksOutline,
    bookmarks, 
    eyeOutline, 
    eye, 
    create,
    folderOutline,
    folder,
    chatbubblesOutline,
    chatbubbles,
    clipboardOutline,
    clipboard,
    trashOutline,
    trash,
    createOutline
  } from 'ionicons/icons';
  
  import { 
    mapGetters 
  } from "vuex";
  
  import axios from 'axios'
  import toast from '@/plugins/toast'
  import editarSiniestro from '@/views/siniestros/editarSiniestro';

  export default defineComponent({
  name: 'TableSiniestro',
  props: ['siniestros'],
  setup(){
    return {
      arrowUpOutline,
      arrowDownOutline,
      fileTrayFullOutline,
      fileTrayFull,
      downloadOutline,
      download,
      bookmarksOutline,
      bookmarks, 
      eyeOutline, 
      eye, 
      create,
      folderOutline,
      folder,
      chatbubblesOutline,
      chatbubbles,
      clipboardOutline,
      clipboard,
      trashOutline,
      trash,
      createOutline
    }
  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  mounted(){
  },
  methods:{
     showNota(siniestro){
      //location.href = 'chat/'+siniestro.chat.id+'?siniestro_id='+siniestro.id
      var win = window.open('chat/'+siniestro.chat.id+'?siniestro_id='+siniestro.id)
      win.focus();
    },
    showDocumentos(siniestro){
      //this.$router.push({ name: 'documentos' , params : {siniestro_id : siniestro_id}})
      var win = window.open('documentos/'+siniestro.id)
      win.focus();
    },
     async openModalSiniestroEdit(siniestro) {
      const modal = await modalController
        .create({
          component: editarSiniestro,
          cssClass: 'class-modal',
          keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {data_siniestro : siniestro}
        })
      
      modal.present();
      
      modal.onDidDismiss().then((data) => {
        /*
          this.getUser.role.name != 'Administrador' ? 
            this.getSiniestrosByUser() : 
            this.getSiniestros();
        */
        console.log(data)
      })
    },
    async desarchivar(siniestro){
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: '¡Confirmación!',
        message: '¿Realmente desea desarchivar este siniestro?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel:', blah);
            },
          },
          {
            text: 'Desarchivar',
            handler: async () => {
              var loading = await toast.showLoading()

              await loading.present();
              
              axios.put('/api/siniestros/'+siniestro.id+'/desarchivar')
              .then(() => {
                loading.dismiss()
                toast.openToast("Siniestro desarchivar correctamente","success",2000)
                this.$emit("reload")
                this.getSiniestrosByUser(this.colaborador);
               
              })
              .catch(error => {
                loading.dismiss()
                console.log(error)
              })
              },
            },
          ],
        });

      return alert.present();
    },
    async archivar(siniestro){
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: '¡Confirmación!',
        message: '¿Realmente desea archivar este siniestro?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel:', blah);
            },
          },
          {
            text: 'Archivar',
            handler: async () => {
              var loading = await toast.showLoading()

              await loading.present();
              
              axios.put('/api/siniestros/'+siniestro.id+'/archivar')
              .then(() => {
                loading.dismiss()
                toast.openToast("Siniestro archivado correctamente","success",2000)
                this.$emit("reload")
                this.getSiniestrosByUser(this.colaborador);
              })
              .catch(error => {
                loading.dismiss()
                console.log(error)
              })
              },
            },
          ],
        });

      return alert.present();
    },
    async eliminarSiniestroConfirmacion(siniestro) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: '¡Confirmación!',
        message: '¿Realmente desea eliminar este siniestro?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel:', blah);
            },
          },
          {
            text: 'Eliminar',
            handler: async () => {
              var loading = await toast.showLoading()

              await loading.present();
              
              console.log('Confirm Okay');
              
              axios.delete('/api/siniestros/'+siniestro.id)
              .then(data =>{
                toast.openToast("Siniestro eliminado exitosamente","error",2000)
                loading.dismiss()
                this.$emit("reload")
                this.getUser.role.name != 'Administrador' ? this.getSiniestrosByUser() : this.getSiniestros();
                console.log(data)
              }).catch(err =>{
                loading.dismiss()
                console.log(err)
              })
            },
          },
        ],
      });
      return alert.present();
    },
    enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style>
.section-divider {
  margin: 20px 0 0 15px;
}

</style>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}

.input-text{
  margin-bottom: 15px;
  padding-left: 17px;
  background: #F5F5F5;
  border: 1px solid rgba(183, 183, 183, 0.3);
  box-sizing: border-box;
  border-radius: 24px;
  width :90%;
  height : 48px;
}


</style>