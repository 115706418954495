<template>
  <nav aria-label="page navigation example">
    <ul class="pagination justify-content-center">
       <li  
        :class="{'page-item' : true , 'disabled' : !data?.first_page_url }"
      >
        <a 
          class="page-link"   
          @click="page(data?.first_page_url)" 
          tabindex="-1"
        >
          Primero
        </a>
      </li>
      <li 
        v-for="link in data?.links" 
        :key="link"
        :class="{'page-item' : true , 'active' : link?.active }" 
      >
        <a
          class="page-link" 
          @click="page(link?.url)" 
          tabindex="-1"
          v-html="link?.label"
        >
          
        </a>
      </li>
      <li 
        :class="{'page-item' : true , 'disabled' : !data?.last_page_url }"
      >
        <a 
          class="page-link"  
          @click="page(data?.last_page_url)"
        >
          Ultimo
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>

  import { 
    defineComponent 
  } from 'vue';
  
  export default defineComponent({
    name: 'Paginate',
    props: ['data'],
    methods:{
      page(url){
        this.$emit("page",url)
      }
    }
  });

</script>